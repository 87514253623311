var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      ref: "purchacesOrderModalRef",
      attrs: {
        "header-class": "header-class-modal-doc-package",
        "no-close-on-backdrop": "",
        "hide-header-close": _vm.$screen.width < 576,
        "dialog-class": "modal-to-screen",
        "hide-backdrop": _vm.$screen.width < 576,
        "hide-header": _vm.$screen.width < 576,
        id: "modal-to-screen",
        "footer-class": _vm.$screen.width < 576 ? "po-footer-modal" : "",
        size: "xl",
        "no-close-on-esc": "",
        "modal-class": "mui-animation",
        fade: false,
      },
      on: { close: _vm.handleCancelPolModal, cancel: _vm.handleCancelPolModal },
      scopedSlots: _vm._u([
        {
          key: "modal-header",
          fn: function ({ close }) {
            return [
              _c("div", { staticClass: "d-flex flex-row w-100" }, [
                _c("div", { staticClass: "d-flex flex-row w-100" }, [
                  _c("h5", [_vm._v(_vm._s(_vm.rendTitleModal))]),
                ]),
                _c("div", { staticClass: "d-flex flex-row w-100" }, [
                  _vm.isEditMode
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "d-flex w-80 align-items-center justify-content-end w-100",
                        },
                        [
                          !_vm.isFilmSingle
                            ? _c(
                                "span",
                                { staticClass: "border-encoded p-1" },
                                [
                                  _vm._v(
                                    "\n\t\t\t\t\t\t" +
                                      _vm._s(_vm.FormMSG(257, "Encoded by:")) +
                                      " "
                                  ),
                                  _c("b", [
                                    _vm._v(
                                      _vm._s(_vm.encoder(_vm.purchaceOrder))
                                    ),
                                  ]),
                                ]
                              )
                            : _vm._e(),
                        ]
                      )
                    : _vm._e(),
                  _c("div", { class: _vm.isEditMode ? "w-20" : "w-100" }, [
                    _c(
                      "button",
                      {
                        staticClass: "btn-close",
                        attrs: { type: "button" },
                        on: {
                          click: function ($event) {
                            return close()
                          },
                        },
                      },
                      [_vm._v("×")]
                    ),
                  ]),
                ]),
              ]),
            ]
          },
        },
        {
          key: "modal-footer",
          fn: function ({ ok, cancel }) {
            return [
              _vm.isEditable
                ? _c(
                    "b-button",
                    {
                      class: _vm.$screen.width < 576 ? "w-100" : "",
                      attrs: {
                        variant: "success",
                        disabled:
                          !_vm.isPoDescriptionValide ||
                          _vm.saveActivePoHorsContract === false,
                      },
                      on: { click: (e) => _vm.submitPurchaseOrder(e) },
                    },
                    [
                      _vm._v(
                        "\n\t\t\t" +
                          _vm._s(_vm.FormMSG(9909, "Save")) +
                          "\n\t\t"
                      ),
                    ]
                  )
                : _vm._e(),
              !_vm.isOnRemoved
                ? _c(
                    "b-button",
                    {
                      class: _vm.$screen.width < 576 ? "w-100" : "",
                      attrs: { variant: "light" },
                      on: { click: cancel },
                    },
                    [
                      _vm._v(
                        "\n\t\t\t" +
                          _vm._s(_vm.FormMSG(998, "Close")) +
                          "\n\t\t"
                      ),
                    ]
                  )
                : _vm._e(),
            ]
          },
        },
      ]),
      model: {
        value: _vm.isOpen,
        callback: function ($$v) {
          _vm.isOpen = $$v
        },
        expression: "isOpen",
      },
    },
    [
      _vm.purchaceOrder
        ? _c(
            "div",
            {
              staticClass: "card card-border-blue-light px-4 py-3",
              class:
                _vm.$screen.width > 576
                  ? "container-layout"
                  : "container-mobile",
            },
            [
              _vm.$screen.width <= 576
                ? _c(
                    "div",
                    {
                      staticClass: "mb-2 mt-2",
                      staticStyle: { display: "flex" },
                    },
                    [
                      _c("i", {
                        staticClass: "fa fa-play",
                        staticStyle: { "font-size": "17px" },
                        attrs: { "aria-hidden": "true" },
                      }),
                      _vm._v("  \n\t\t\t"),
                      _c("h2", [
                        _vm._v(_vm._s(_vm.FormMSG(27700, "Attach Quote"))),
                      ]),
                    ]
                  )
                : _vm._e(),
              _c(
                "div",
                [
                  _c(
                    "b-alert",
                    {
                      attrs: {
                        show:
                          _vm.setallowPoEntryOutsideContract === 0 &&
                          _vm.showAlert
                            ? true
                            : false,
                        variant: "warning",
                      },
                    },
                    [
                      _c("h4", { staticClass: "alert-heading" }, [
                        _vm._v(_vm._s(_vm.FormMSG(252, "Warning"))),
                      ]),
                      _c(
                        "div",
                        {
                          staticClass:
                            "d-flex justify-content-start align-items-center w-100",
                          staticStyle: { gap: "8px" },
                        },
                        [
                          _c(
                            "div",
                            [
                              _c(
                                _vm.getLucideIcon(
                                  _vm.ICONS.ALERT_TRIANGLE.name
                                ),
                                {
                                  tag: "component",
                                  attrs: {
                                    color: _vm.ICONS.ALERT_TRIANGLE.color,
                                    size: 40,
                                  },
                                }
                              ),
                            ],
                            1
                          ),
                          _c("div", [
                            _vm._v(
                              _vm._s(_vm.messageAllowPoEntryOutsideContract)
                            ),
                          ]),
                        ]
                      ),
                    ]
                  ),
                  _c(
                    "b-alert",
                    {
                      attrs: {
                        show:
                          _vm.setallowPoEntryOutsideContract === 1 &&
                          _vm.showAlert
                            ? true
                            : false,
                        variant: "warning",
                      },
                    },
                    [
                      _c("h4", { staticClass: "alert-heading" }, [
                        _vm._v(_vm._s(_vm.FormMSG(252, "Warning"))),
                      ]),
                      _c(
                        "div",
                        {
                          staticClass:
                            "d-flex flex-row justify-content-start align-items-center w-100",
                          staticStyle: { gap: "8px" },
                        },
                        [
                          _c(
                            "div",
                            [
                              _c(
                                _vm.getLucideIcon(
                                  _vm.ICONS.ALERT_TRIANGLE.name
                                ),
                                {
                                  tag: "component",
                                  attrs: {
                                    color: _vm.ICONS.ALERT_TRIANGLE.color,
                                    size: 40,
                                  },
                                }
                              ),
                            ],
                            1
                          ),
                          _c("div", [
                            _vm._v(
                              _vm._s(_vm.messageAllowPoEntryOutsideContract)
                            ),
                          ]),
                        ]
                      ),
                    ]
                  ),
                  _c(
                    "b-modal",
                    {
                      attrs: {
                        size: "xs",
                        title: "Hors contract",
                        "no-close-on-backdrop": "",
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "modal-footer",
                            fn: function () {
                              return [
                                _c(
                                  "b-button",
                                  {
                                    staticClass: "float-right",
                                    attrs: { variant: "primary", size: "sm" },
                                    on: { click: _vm.hideModal },
                                  },
                                  [
                                    _vm._v(
                                      " " + _vm._s(_vm.FormMSG(255, "No")) + " "
                                    ),
                                  ]
                                ),
                                _c(
                                  "b-button",
                                  {
                                    staticClass: "float-right",
                                    attrs: { variant: "primary", size: "sm" },
                                    on: {
                                      click: (e) => _vm.addOutsideContract(e),
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n\t\t\t\t\t\t" +
                                        _vm._s(_vm.FormMSG(256, "Yes")) +
                                        "\n\t\t\t\t\t"
                                    ),
                                  ]
                                ),
                              ]
                            },
                            proxy: true,
                          },
                        ],
                        null,
                        false,
                        2796229777
                      ),
                      model: {
                        value: _vm.showPopUp,
                        callback: function ($$v) {
                          _vm.showPopUp = $$v
                        },
                        expression: "showPopUp",
                      },
                    },
                    [
                      _c("b-container", { attrs: { fluid: "" } }, [
                        _c(
                          "div",
                          {
                            staticClass:
                              "d-flex flex-row justify-content-start align-items-center w-100",
                            staticStyle: { gap: "8px" },
                          },
                          [
                            _c(
                              "div",
                              [
                                _c(
                                  _vm.getLucideIcon(
                                    _vm.ICONS.ALERT_TRIANGLE.name
                                  ),
                                  {
                                    tag: "component",
                                    attrs: {
                                      color: _vm.ICONS.ALERT_TRIANGLE.color,
                                      size: 40,
                                    },
                                  }
                                ),
                              ],
                              1
                            ),
                            _c("div", [
                              _vm._v(
                                _vm._s(_vm.messageAllowPoEntryOutsideContract)
                              ),
                            ]),
                          ]
                        ),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm.isEditMode
                ? _c(
                    "div",
                    [
                      _vm.isRefusedPo
                        ? _c(
                            "b-alert",
                            { attrs: { variant: "danger", show: "" } },
                            [
                              _c("b", [
                                _vm._v(
                                  _vm._s(_vm.FormMSG(987897, "Refused for")) +
                                    ":"
                                ),
                              ]),
                              _c("br"),
                              _vm._v(
                                "\n\t\t\t\t" +
                                  _vm._s(_vm.editData.comment) +
                                  "\n\t\t\t"
                              ),
                            ]
                          )
                        : _vm._e(),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "b-form-group",
                {
                  attrs: {
                    label: _vm.FormMSG(1, "Reference"),
                    "label-for": "po-saving-ref",
                  },
                },
                [
                  _c("b-form-input", {
                    attrs: { id: "po-saving-ref", disabled: "" },
                    model: {
                      value: _vm.purchaceOrder.poReference,
                      callback: function ($$v) {
                        _vm.$set(_vm.purchaceOrder, "poReference", $$v)
                      },
                      expression: "purchaceOrder.poReference",
                    },
                  }),
                ],
                1
              ),
              _c("CapturesPackage", {
                attrs: {
                  multiple: "",
                  "can-upload-pdf": "",
                  "preview-mode": "button",
                  "parent-type": "purchase_order",
                  "parent-sub-type": "po",
                  "parent-id": +_vm.purchaceOrder.id,
                  pictures: _vm.rendImagesListForPreviewer,
                  "preview-modal-options": {
                    zIndex: 1041,
                    hideLikeDislike: true,
                    hideCommentButton: true,
                    hideDisLikeButton: true,
                  },
                },
                on: {
                  "update:pictures": function ($event) {
                    _vm.rendImagesListForPreviewer = $event
                  },
                  change: _vm.handleImgsTaken,
                },
              }),
              _vm.$screen.width <= 576
                ? _c(
                    "div",
                    {
                      staticClass: "mb-2 mt-3",
                      staticStyle: { display: "flex" },
                    },
                    [
                      _c("i", {
                        staticClass: "fa fa-play",
                        staticStyle: { "font-size": "17px" },
                        attrs: { "aria-hidden": "true" },
                      }),
                      _vm._v("  \n\t\t\t"),
                      _c("h2", [
                        _vm._v(
                          _vm._s(_vm.FormMSG(27701, "General Information"))
                        ),
                      ]),
                    ]
                  )
                : _vm._e(),
              _c(
                "div",
                { staticClass: "bootstrap-date-selection mt-3" },
                [
                  _c("label", { attrs: { for: "po_date" } }, [
                    _vm._v(_vm._s(_vm.FormMSG(545541, "Delivery date"))),
                  ]),
                  _c("b-form-datepicker", {
                    attrs: {
                      id: "po_date",
                      disabled: !_vm.isEditable,
                      locale: _vm.currentLang,
                    },
                    model: {
                      value: _vm.purchaceOrder.deliveryDate,
                      callback: function ($$v) {
                        _vm.$set(_vm.purchaceOrder, "deliveryDate", $$v)
                      },
                      expression: "purchaceOrder.deliveryDate",
                    },
                  }),
                ],
                1
              ),
              _c(
                "b-form-group",
                {
                  attrs: {
                    label: _vm.FormMSG(3, "Description"),
                    "label-for": "po_description",
                  },
                },
                [
                  _c("b-form-textarea", {
                    attrs: {
                      id: "po_description",
                      placeholder: _vm.FormMSG(545542, "Po's description..."),
                      rows: "3",
                      disabled: !_vm.isEditable,
                      required: "",
                    },
                    model: {
                      value: _vm.purchaceOrder.description,
                      callback: function ($$v) {
                        _vm.$set(_vm.purchaceOrder, "description", $$v)
                      },
                      expression: "purchaceOrder.description",
                    },
                  }),
                ],
                1
              ),
              _c(
                "b-form-group",
                { attrs: { "label-for": "po_amount" } },
                [
                  _c(
                    "b-form-checkbox",
                    {
                      attrs: { id: "po_amount", disabled: !_vm.isEditable },
                      model: {
                        value: _vm.purchaceOrder.invoicedToProduction,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.purchaceOrder,
                            "invoicedToProduction",
                            $$v
                          )
                        },
                        expression: "purchaceOrder.invoicedToProduction",
                      },
                    },
                    [
                      _vm._v(
                        "\n\t\t\t\t" +
                          _vm._s(_vm.FormMSG(11, "Invoiced to production")) +
                          "\n\t\t\t"
                      ),
                    ]
                  ),
                ],
                1
              ),
              _vm.useComponent
                ? _c(
                    "b-form-group",
                    {
                      attrs: {
                        label: _vm.FormMSG(72, "Select Person"),
                        "label-for": "new-expense_ticket-ref",
                      },
                    },
                    [
                      _c("b-form-select", {
                        attrs: {
                          size: "md",
                          options: _vm.userList,
                          "value-field": "value",
                          "text-field": "message",
                        },
                        model: {
                          value: _vm.selectedUser,
                          callback: function ($$v) {
                            _vm.selectedUser = $$v
                          },
                          expression: "selectedUser",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "div",
                [
                  _vm.isEditable
                    ? _c("SupplierSelector", {
                        ref: "supplier_selector_normal",
                        attrs: {
                          title: `${
                            !_vm.isSupplierUpdate
                              ? _vm.FormMSG(250, "Add supplier details")
                              : _vm.FormMSG(251, "Update supplier details")
                          }`,
                          label: _vm.FormMSG(5, "Name of a supplier"),
                          addlabel: _vm.FormMSG(5446, "Add"),
                          "update-label": _vm.FormMSG(10, "Update"),
                          placeholder: _vm.FormMSG(545543, "Filter by name"),
                          "supplier-id": _vm.supplierId,
                          disabled: !_vm.isEditable,
                        },
                        on: {
                          change: _vm.handleSupplierChange,
                          "supplier:unselected": _vm.onSupplierUnselected,
                        },
                      })
                    : _c(
                        "b-form-group",
                        {
                          attrs: {
                            label: _vm.FormMSG(6, "Name of a supplier"),
                          },
                        },
                        [
                          _c("b-form-input", {
                            attrs: {
                              value: _vm.purchaceOrder.supplierName,
                              disabled: "",
                            },
                          }),
                        ],
                        1
                      ),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "mt-3" },
                [
                  _vm.isEditable
                    ? _c("DepartementSelection", {
                        attrs: {
                          "hide-category": "",
                          "is-new": _vm.isNew,
                          disabled: !_vm.isEditable || _vm.poItems.length > 0,
                          "edit-data": _vm.editData,
                          department: _vm.purchaceOrder.department,
                        },
                        on: { change: _vm.handleChangeDepartment },
                      })
                    : _c(
                        "b-form-group",
                        {
                          attrs: { label: _vm.FormMSG(2, "Department's name") },
                        },
                        [
                          _c("b-form-input", {
                            attrs: {
                              value: _vm.purchaceOrder.departmentName,
                              disabled: "",
                            },
                          }),
                        ],
                        1
                      ),
                ],
                1
              ),
              _vm.$screen.width <= 576
                ? _c(
                    "div",
                    {
                      staticClass: "mb-2 mt-2",
                      staticStyle: { display: "flex" },
                    },
                    [
                      _c("i", {
                        staticClass: "fa fa-play",
                        staticStyle: { "font-size": "17px" },
                        attrs: { "aria-hidden": "true" },
                      }),
                      _vm._v("  \n\t\t\t"),
                      _c("h2", [
                        _vm._v(_vm._s(_vm.FormMSG(277, "PO summary"))),
                      ]),
                    ]
                  )
                : _vm._e(),
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    { attrs: { sm: "12", md: "4", lg: "4", xl: "4" } },
                    [
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            label: _vm.FormMSG(4, "Total amount"),
                            "label-for": "po_amount",
                          },
                        },
                        [
                          _c(
                            "b-input-group",
                            [
                              _c("b-form-input", {
                                attrs: {
                                  type: "number",
                                  placeholder: "0.00",
                                  step: "0.01",
                                  disabled: !_vm.purchaceOrder.notDetailed,
                                },
                                model: {
                                  value: _vm.purchaseOrderTotalAmount,
                                  callback: function ($$v) {
                                    _vm.purchaseOrderTotalAmount = $$v
                                  },
                                  expression: "purchaseOrderTotalAmount",
                                },
                              }),
                              _c(
                                "b-input-group-append",
                                [
                                  _c(
                                    "b-input-group-text",
                                    [
                                      _c("currency-svg", {
                                        attrs: {
                                          color: "#06263E",
                                          opacity: "0.85",
                                          width: "15",
                                          height: "15",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { sm: "12", md: "4", lg: "4", xl: "4" } },
                    [
                      _c(
                        "b-form-group",
                        { attrs: { label: _vm.FormMSG(12, "Total VAT") } },
                        [
                          _c(
                            "b-input-group",
                            [
                              _c("b-form-input", {
                                attrs: {
                                  type: "number",
                                  placeholder: "0.00",
                                  step: "0.01",
                                  disabled: !_vm.purchaceOrder.notDetailed,
                                },
                                model: {
                                  value: _vm.purchaseOrderTotalVat,
                                  callback: function ($$v) {
                                    _vm.purchaseOrderTotalVat = $$v
                                  },
                                  expression: "purchaseOrderTotalVat",
                                },
                              }),
                              _c(
                                "b-input-group-append",
                                [
                                  _c(
                                    "b-input-group-text",
                                    [
                                      _c("currency-svg", {
                                        attrs: {
                                          color: "#06263E",
                                          opacity: "0.85",
                                          width: "15",
                                          height: "15",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { sm: "12", md: "4", lg: "4", xl: "4" } },
                    [
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            label: _vm.FormMSG(425, "Total amount (tax incl.)"),
                          },
                        },
                        [
                          _c(
                            "b-input-group",
                            [
                              _c("b-form-input", {
                                attrs: { disabled: "" },
                                model: {
                                  value: _vm.pOTotalAmountTTC,
                                  callback: function ($$v) {
                                    _vm.pOTotalAmountTTC = $$v
                                  },
                                  expression: "pOTotalAmountTTC",
                                },
                              }),
                              _c(
                                "b-input-group-append",
                                [
                                  _c(
                                    "b-input-group-text",
                                    [
                                      _c("currency-svg", {
                                        attrs: {
                                          color: "#06263E",
                                          opacity: "0.85",
                                          width: "15",
                                          height: "15",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    [
                      _c(
                        "b-form-checkbox",
                        {
                          attrs: {
                            value: true,
                            "unchecked-value": false,
                            disabled: _vm.poItems.length > 0,
                          },
                          model: {
                            value: _vm.purchaceOrder.notDetailed,
                            callback: function ($$v) {
                              _vm.$set(_vm.purchaceOrder, "notDetailed", $$v)
                            },
                            expression: "purchaceOrder.notDetailed",
                          },
                        },
                        [
                          _vm._v(
                            "\n\t\t\t\t\t" +
                              _vm._s(_vm.FormMSG(841, "PO not detailed")) +
                              "\n\t\t\t\t"
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "b-collapse",
                { attrs: { visible: _vm.purchaceOrder.notDetailed === true } },
                [
                  _c(
                    "div",
                    { staticClass: "mt-3" },
                    [
                      _c("department-selector", {
                        attrs: {
                          "hide-department": "",
                          "is-new": false,
                          department: _vm.purchaceOrder.department,
                          category: _vm.purchaceOrder.itemCategory,
                          "edit-data": _vm.purchaceOrder,
                          "is-submitted": _vm.submitDepartmentSelector,
                        },
                        on: {
                          change: _vm.handleChangeDepartmentSelector,
                          "department:selector:invalid": _vm.isInvalidateFields,
                        },
                      }),
                    ],
                    1
                  ),
                ]
              ),
              _c(
                "b-collapse",
                { attrs: { visible: _vm.purchaceOrder.notDetailed === false } },
                [
                  _vm.purchaceOrder.notDetailed === false
                    ? _c(
                        "div",
                        {
                          staticClass: "mt-3",
                          class: {
                            "card card-border-blue-light px-3":
                              _vm.$screen.width > 576,
                          },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "sub-title-layout pb-3",
                              staticStyle: { "font-size": "18px" },
                            },
                            [
                              _vm._v(
                                "\n\t\t\t\t\t" +
                                  _vm._s(_vm.FormMSG(7, "PO's items")) +
                                  "\n\t\t\t\t"
                              ),
                            ]
                          ),
                          _vm.$screen.width > 576
                            ? _c(
                                "div",
                                [
                                  _c("b-table", {
                                    ref: "transportsTable",
                                    staticStyle: { "text-align": "left" },
                                    attrs: {
                                      selectable: "",
                                      bordered: "",
                                      small: "",
                                      responsive: "sm",
                                      "sticky-header": "700px",
                                      "head-variant": "dark",
                                      items: _vm.poItems,
                                      fields: _vm.poItemFields,
                                      hover: "",
                                      "selected-variant": "primary",
                                      "select-mode": "single",
                                      "empty-text": _vm.FormMSG(
                                        568,
                                        "No PO's items"
                                      ),
                                      "show-empty": "",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "cell(action)",
                                          fn: function (data) {
                                            return [
                                              _vm.isEditable
                                                ? _c(
                                                    "b-button",
                                                    {
                                                      staticClass:
                                                        "btn bg-transparent border-0 px-2",
                                                      attrs: {
                                                        variant: "primary",
                                                        size: "sm",
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.handleUpdatePoItem(
                                                            data.item
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        _vm.getLucideIcon(
                                                          _vm.ICONS.EDIT.name
                                                        ),
                                                        {
                                                          tag: "component",
                                                          attrs: {
                                                            color:
                                                              _vm.ICONS.EDIT
                                                                .color,
                                                            size: 20,
                                                            "stroke-width": 2.25,
                                                          },
                                                        }
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                              _vm.isEditable
                                                ? _c(
                                                    "b-button",
                                                    {
                                                      staticClass:
                                                        "btn bg-transparent border-0",
                                                      attrs: {
                                                        variant: "danger",
                                                        size: "sm",
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.handleDeletePoItem(
                                                            data.item
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        _vm.getLucideIcon(
                                                          _vm.ICONS.TRASH.name
                                                        ),
                                                        {
                                                          tag: "component",
                                                          attrs: {
                                                            color:
                                                              _vm.ICONS.TRASH
                                                                .color,
                                                            size: 20,
                                                            "stroke-width": 2.25,
                                                          },
                                                        }
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      5483668
                                    ),
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.$screen.width <= 576
                            ? _c(
                                "div",
                                [
                                  _c("card-list-builder", {
                                    attrs: {
                                      "use-new-version": true,
                                      "toggle-mode": true,
                                      items: _vm.poItems,
                                      "horizontal-mode": true,
                                      "show-quantity-on-title": true,
                                      fields: _vm.poMobileFields,
                                      "header-fields": _vm.poHeaderMobileFields,
                                      "key-for-title": "description",
                                      "key-for-quantity": "quantity",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "action",
                                          fn: function (data) {
                                            return [
                                              _vm.isEditable
                                                ? _c(
                                                    "b-button",
                                                    {
                                                      staticClass:
                                                        "btn bg-transparent border-0 px-2",
                                                      attrs: {
                                                        variant: "primary",
                                                        size: "sm",
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.handleUpdatePoItem(
                                                            data.item
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        _vm.getLucideIcon(
                                                          _vm.ICONS.EDIT.name
                                                        ),
                                                        {
                                                          tag: "component",
                                                          attrs: {
                                                            color:
                                                              _vm.ICONS.EDIT
                                                                .color,
                                                            size: 20,
                                                            "stroke-width": 2.25,
                                                          },
                                                        }
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                              _vm.isEditable
                                                ? _c(
                                                    "b-button",
                                                    {
                                                      staticClass:
                                                        "btn bg-transparent border-0",
                                                      attrs: {
                                                        variant: "danger",
                                                        size: "sm",
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.handleDeletePoItem(
                                                            data.item
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        _vm.getLucideIcon(
                                                          _vm.ICONS.TRASH.name
                                                        ),
                                                        {
                                                          tag: "component",
                                                          attrs: {
                                                            color:
                                                              _vm.ICONS.TRASH
                                                                .color,
                                                            size: 20,
                                                            "stroke-width": 2.25,
                                                          },
                                                        }
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      989397971
                                    ),
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                          _c(
                            "div",
                            {
                              staticClass:
                                "w-100 d-flex justify-content-center",
                            },
                            [
                              _vm.isEditable
                                ? _c(
                                    "b-button",
                                    {
                                      class: `${
                                        _vm.$screen.width <= 576
                                          ? "w-100"
                                          : "w-25"
                                      }`,
                                      attrs: {
                                        disabled: !_vm.saveActivePoHorsContract,
                                        block: "",
                                        variant: "outline-secondary",
                                      },
                                      on: { click: _vm.toggleItemModal },
                                    },
                                    [
                                      _vm._v(
                                        "\n\t\t\t\t\t\t" +
                                          _vm._s(_vm.FormMSG(9, "Add items")) +
                                          "\n\t\t\t\t\t"
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ]
                      )
                    : _vm._e(),
                ]
              ),
              _vm.editData &&
              _vm.purchaceOrder.validated !== 0 &&
              _vm.purchaceOrder.validated !== 4 &&
              _vm.purchaceOrder.validated !== 16
                ? _c("div", { staticClass: "text-right" }, [
                    _vm.$screen.width >= 992
                      ? _c(
                          "div",
                          {
                            staticClass:
                              "d-flex justify-content-end align-items-center",
                          },
                          [
                            _c(
                              "b-button",
                              {
                                staticClass: "mr-3 d-flex align-items-center",
                                attrs: {
                                  size: "md",
                                  variant: "outline-primary",
                                },
                                on: { click: _vm.downloadXls },
                              },
                              [
                                _c(_vm.getLucideIcon(_vm.ICONS.DOWNLOAD.name), {
                                  tag: "component",
                                  staticClass: "mr-2",
                                  attrs: { size: 20 },
                                }),
                                _c("div", [
                                  _vm._v(
                                    "\n\t\t\t\t\t\t" +
                                      _vm._s(
                                        _vm.FormMSG(
                                          222,
                                          "Download this Purchase Order"
                                        )
                                      ) +
                                      "\n\t\t\t\t\t"
                                  ),
                                ]),
                              ],
                              1
                            ),
                            _c(
                              "b-button",
                              {
                                staticClass: "d-flex align-items-center",
                                attrs: {
                                  size: "md",
                                  variant: "outline-primary",
                                  disabled: _vm.isLoadingSendMail,
                                },
                                on: { click: _vm.sendXlsByEmail },
                              },
                              [
                                _c("b-spinner", {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: _vm.isLoadingSendMail,
                                      expression: "isLoadingSendMail",
                                    },
                                  ],
                                  staticClass: "mr-2",
                                  attrs: { small: "" },
                                }),
                                !_vm.isLoadingSendMail
                                  ? _c(_vm.getLucideIcon(_vm.ICONS.SEND.name), {
                                      tag: "component",
                                      staticClass: "mr-2",
                                      attrs: { size: 20 },
                                    })
                                  : _vm._e(),
                                _c("div", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.FormMSG(
                                        500,
                                        "Send me this purchace order by Email"
                                      )
                                    )
                                  ),
                                ]),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.$screen.width < 992
                      ? _c(
                          "div",
                          {
                            staticClass:
                              "d-flex mt-3 justify-content-end align-items-center",
                          },
                          [
                            _c(
                              "b-button",
                              {
                                staticClass:
                                  "mr-3 d-flex justify-content-center",
                                attrs: {
                                  size: "md",
                                  variant: "primary",
                                  id: "btn-download",
                                },
                                on: { click: _vm.downloadXls },
                              },
                              [
                                _c(_vm.getLucideIcon(_vm.ICONS.DOWNLOAD.name), {
                                  tag: "component",
                                  attrs: { size: 20, "stroke-width": 2.25 },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "b-tooltip",
                              {
                                attrs: {
                                  target: "btn-download",
                                  placement: "top",
                                },
                              },
                              [
                                _vm._v(
                                  "\n\t\t\t\t\t" +
                                    _vm._s(
                                      _vm.FormMSG(
                                        222,
                                        "Download this Purchase Order"
                                      )
                                    ) +
                                    "\n\t\t\t\t"
                                ),
                              ]
                            ),
                            _c(
                              "b-button",
                              {
                                staticClass: "d-flex justify-content-center",
                                attrs: {
                                  size: "md",
                                  variant: "primary",
                                  id: "btn-envelope-closed",
                                },
                                on: { click: _vm.sendXlsByEmail },
                              },
                              [
                                _c(_vm.getLucideIcon(_vm.ICONS.SEND.name), {
                                  tag: "component",
                                  attrs: { size: 20, "stroke-width": 2.25 },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "b-tooltip",
                              {
                                attrs: {
                                  target: "btn-envelope-closed",
                                  placement: "top",
                                },
                              },
                              [
                                _vm._v(
                                  "\n\t\t\t\t\t" +
                                    _vm._s(
                                      _vm.FormMSG(
                                        500,
                                        "Send me this purchace order by Email"
                                      )
                                    ) +
                                    "\n\t\t\t\t"
                                ),
                              ]
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                  ])
                : _vm._e(),
              _vm.currentPoId
                ? _c("POItemModal", {
                    attrs: {
                      "edit-data": _vm.currentPOSItem,
                      "current-po-id": _vm.currentPoId,
                      department: _vm.purchaceOrder.department,
                    },
                    on: { "po-item:submitted": _vm.handleTotalAmountChange },
                    model: {
                      value: _vm.isItemModalOpen,
                      callback: function ($$v) {
                        _vm.isItemModalOpen = $$v
                      },
                      expression: "isItemModalOpen",
                    },
                  })
                : _vm._e(),
              _c(
                "b-modal",
                {
                  staticClass: "modal-success",
                  attrs: {
                    "header-class": "header-class-modal-doc-package",
                    title: _vm.FormMSG(21211, "Success!"),
                    "ok-variant": "success",
                    "ok-only": "",
                    "modal-class": "mui-animation",
                    fade: false,
                  },
                  on: {
                    ok: function ($event) {
                      _vm.successModal = false
                    },
                  },
                  model: {
                    value: _vm.successModal,
                    callback: function ($$v) {
                      _vm.successModal = $$v
                    },
                    expression: "successModal",
                  },
                },
                [
                  _vm._v(
                    "\n\t\t\t" +
                      _vm._s(
                        _vm.FormMSG(
                          601,
                          "The purchace order has been sent to your Email address"
                        )
                      ) +
                      "\n\t\t"
                  ),
                ]
              ),
              _c("script-export-modal", {
                attrs: {
                  items: _vm.scripts,
                  custom: _vm.customReport,
                  "export-type": "po",
                },
                on: {
                  "script-export-modal:loading": _vm.handleMailSent,
                  "script-export-modal:closed": _vm.poRerpotOnClosed,
                },
                model: {
                  value: _vm.isScriptPoReportOpen,
                  callback: function ($$v) {
                    _vm.isScriptPoReportOpen = $$v
                  },
                  expression: "isScriptPoReportOpen",
                },
              }),
            ],
            1
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }